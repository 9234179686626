<template>
  <!-- LAYOUT -->

  <h2>Accordion</h2>

  <div class="v-padding" style="height: 50vh;">
    <Accordion :panels="[
        { key: 'foo', title: 'One Foo' },
        { key: 'bar', title: 'The Bar' },
        { key: 'baz', title: 'Bazinga!' },
      ]">
      <template v-slot:foo>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
      </template>
      <template v-slot:bar>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
      </template>
      <template v-slot:baz>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo excepturi facere id corrupti aspernatur alias nostrum quis veniam eveniet necessitatibus? Est impedit quidem, voluptate consequatur nulla enim fugit eaque vel quibusdam perferendis magnam omnis debitis! Aperiam, adipisci enim? Quis molestias aliquid praesentium fugiat alias id sapiente dolores quia quasi iusto accusamus quaerat hic, voluptas culpa adipisci provident itaque magni, perferendis exercitationem! Corrupti maiores rem doloribus animi laudantium dolore quo saepe, voluptas voluptatibus hic, dicta ullam perspiciatis eveniet harum! Veniam quibusdam rerum natus nam commodi unde, earum tempore cum ex. Officiis dignissimos architecto fugit rem distinctio sint molestias culpa voluptates perferendis quod ratione possimus quos necessitatibus quisquam animi eius similique ea, eaque labore ex natus quia voluptas. Unde excepturi asperiores nobis, veritatis aut sint tempora omnis, quidem voluptatibus totam tenetur explicabo ea quas alias ex, doloribus eius reiciendis placeat magni dicta a fugiat reprehenderit culpa deleniti. Voluptas corporis libero, similique odit blanditiis nihil quam a expedita! Deleniti quam dolorem minima. Molestiae error asperiores numquam dolorem velit eaque ipsa porro ipsam molestias consequuntur consectetur aut sunt quae hic perspiciatis aspernatur corporis, quasi a assumenda laudantium animi dolorum modi earum! Et neque debitis cumque sapiente, fugit laborum autem, rerum harum tenetur cupiditate corporis!</p>
      </template>
    </Accordion>
  </div>

  <h2>Tabs</h2>

  <h3>Default tabs</h3>

  <div class="v-padding">
    <Tabs v-model="active1">
      <Tab title="Tab 0">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
        neque quo ab totam libero fugit repellat magnam esse! Voluptate
        pariatur nam ipsum voluptas? Repudiandae ipsum ipsa deserunt ullam
        assumenda similique?
      </Tab>
      <Tab title="Tab 1">
        Maiores neque quo ab totam libero fugit repellat magnam esse!
        Voluptate pariatur nam ipsum voluptas? Repudiandae ipsum ipsa
        deserunt ullam assumenda similique?
      </Tab>
      <Tab title="Tab 2">
        Repudiandae ipsum ipsa deserunt ullam assumenda similique? Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Maiores neque
        quo ab totam libero fugit repellat magnam esse!
      </Tab>
    </Tabs>
  </div>

  <h3>Simple tabs</h3>

  <div class="v-padding">
    <Tabs v-model="active2" layout="simple">
      <Tab title="Tab one">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
        neque quo ab totam libero fugit repellat magnam esse! Voluptate
        pariatur nam ipsum voluptas? Repudiandae ipsum ipsa deserunt ullam
        assumenda similique?
      </Tab>
      <Tab title="Tab two">
        Maiores neque quo ab totam libero fugit repellat magnam esse!
        Voluptate pariatur nam ipsum voluptas? Repudiandae ipsum ipsa
        deserunt ullam assumenda similique?
      </Tab>
      <Tab title="Tab three">
        Repudiandae ipsum ipsa deserunt ullam assumenda similique? Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Maiores neque
        quo ab totam libero fugit repellat magnam esse!
      </Tab>
    </Tabs>
  </div>

  <h3>Sidebar tabs</h3>

  <div class="v-padding">
    <Tabs v-model="active3" layout="sidebar">
      <Tab title="Tab 0">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
        neque quo ab totam libero fugit repellat magnam esse! Voluptate
        pariatur nam ipsum voluptas? Repudiandae ipsum ipsa deserunt ullam
        assumenda similique?
      </Tab>
      <Tab title="Tab 1 very very very very very very very very very very long">
        Maiores neque quo ab totam libero fugit repellat magnam esse!
        Voluptate pariatur nam ipsum voluptas? Repudiandae ipsum ipsa
        deserunt ullam assumenda similique?
      </Tab>
      <Tab title="Tab 2">
        Repudiandae ipsum ipsa deserunt ullam assumenda similique? Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Maiores neque
        quo ab totam libero fugit repellat magnam esse!
      </Tab>
    </Tabs>
  </div>

  <hr>

  <h2>TabsMaterial</h2>

  <TabsMaterial
    :tabs="[
      { urlName: 'home', text: 'Accueil' },
      { urlName: 'colors', text: 'Styleguide colors' },
      { urlName: 'home', text: 'Accueil' },
      { urlName: 'colors', text: 'Styleguide colors' },
      { urlName: 'home', text: 'Accueil' },
      { urlName: 'colors', text: 'Styleguide colors' },
      { urlName: 'typography', text: 'Styleguide typography' },
      { urlName: 'icons', text: 'Styleguide icons' },
      { urlName: 'buttons', text: 'Styleguide buttons' },
      { urlName: 'layout', text: 'Styleguide layout' },
      { urlName: 'forms', text: 'Styleguide forms' },
      { urlName: 'forms-advanced', text: 'Styleguide forms advanced' },
      { urlName: 'lists', text: 'Styleguide lists' },
      { urlName: 'tables', text: 'Styleguide tables' },
      { urlName: 'misc', text: 'Styleguide misc' },
      { urlName: 'search', text: 'Styleguide search' },
      { urlName: 'fetchservice', text: 'Styleguide fetch service' },
    ]"
  />

  <hr>

  <h2>Timeline</h2>

  <h3>Numeric timeline</h3>

  <div class="v-padding bg-gray-lightestest margin-bottom">
    <Timeline :index="3" :last-step-to-right="false" />
  </div>

  <h3>Text timeline</h3>

  <div class="v-padding bg-gray-lightestest">
    <Timeline
      :index="1"
      :steps-data="[
        'Modalités',
        'Entités',
        'Récapitulatif',
      ]"
    />
  </div>

  <hr>

  <h2>Dropdowns</h2>

  <h3>ToolDropdown</h3>

  <div class="v-padding" style="display: flex; justify-content: space-between;">
    <ToolDropdown
      dropdown-align="left"
    >
      <MenuItem
        :item-data="{
          label: 'Supprimer',
          iconName: 'trash-alt',
        }"
        @click="modalDeleteUser = true"
      />
      <MenuItem
        :item-data="{
          label: 'Désactiver',
          iconName: 'user-times',
        }"
      />
      <MenuItem
        :item-data="{
          label: 'Ré-initialiser le mot de passe',
          iconName: 'key',
        }"
      />
      <MenuItem
        :item-data="{
          label: 'Voir les logs',
          route: { name: 'login' },
          iconName: 'clipboard-list',
        }"
      />
      <MenuItem
        :item-data="{
          label: 'Droits',
          route: { name: 'login' },
          iconName: 'lock',
        }"
      />
    </ToolDropdown>

    <ToolDropdown>
      <MenuItem
        :item-data="{
          label: 'Supprimer',
          iconName: 'trash-alt',
        }"
        @click="modalDeleteUser = true"
      />
      <MenuItem
        :item-data="{
          label: 'Désactiver',
          iconName: 'user-times',
        }"
      />
      <MenuItem
        :item-data="{
          label: 'Ré-initialiser le mot de passe',
          iconName: 'key',
        }"
        @click="modalReinitUserPwd = true"
      />
      <MenuItem
        :item-data="{
          label: 'Voir les logs',
          route: { name: 'login' },
          iconName: 'clipboard-list',
        }"
      />
      <MenuItem
        :item-data="{
          label: 'Droits',
          route: { name: 'login' },
          iconName: 'lock',
        }"
      />
    </ToolDropdown>
  </div>

  <div class="v-padding" style="display: flex; justify-content: space-between;">
    <ToolDropdown
      dropdown-align="left"
      toggle-btn-text="Ajouter un facteur"
    >
      <MenuItem
        :item-data="{
          label: 'Personnalisé',
          iconName: 'trash-alt',
        }"
        @click="modalDeleteUser = true"
      />
      <MenuItem
        :item-data="{
          label: 'Santé végétale',
          iconName: 'user-times',
        }"
      />
      <MenuItem
        :item-data="{
          label: 'Fertilisation',
          iconName: 'key',
        }"
      />
      <MenuItem
        :item-data="{
          label: 'Semence',
          route: { name: 'login' },
          iconName: 'clipboard-list',
        }"
      />
    </ToolDropdown>
  </div>

  <h3>ToolMenu</h3>

  <ToolMenu>
    <MenuItem
      :item-data="{
        label: 'Supprimer',
        iconName: 'trash-alt',
      }"
      @click="modalDeleteUser = true"
    />
    <MenuItem
      :item-data="{
        label: 'Désactiver',
        iconName: 'user-times',
      }"
    />
    <MenuItem
      :item-data="{
        label: 'Ré-initialiser le mot de passe',
        iconName: 'key',
      }"
    />
    <MenuItem
      :item-data="{
        label: 'Voir les logs',
        route: { name: 'login' },
        iconName: 'clipboard-list',
      }"
    />
    <MenuItem
      :item-data="{
        label: 'Droits',
        route: { name: 'login' },
        iconName: 'lock',
      }"
    />
  </ToolMenu>

  <div class="v-padding">
    <p><em>Should appear teleported in the topbar</em></p>
  </div>

  <h3>Navigation dropdown</h3>

  <NavigationDropdown
    :items-data="[
      {
        key: 'protocol-details',
        route: { name: 'home' },
        label: 'Fiche protocole',
        iconName: 'square',
      },
      {
        key: 'protocol-versions',
        route: { name: 'home' },
        label: 'Versions',
        iconName: 'square',
      },
      {
        key: 'protocol-sharing',
        route: { name: 'home' },
        label: 'Partages',
        iconName: 'square',
      },
      {
        key: 'protocol-files',
        route: { name: 'home' },
        label: 'Fichiers',
        iconName: 'square',
      },
    ]"
  >
  </NavigationDropdown>

  <div class="v-padding">
    <p><em>Should appear teleported in the topbar</em></p>
  </div>

  <hr>

  <h2>Modals</h2>

  <!-- MODALS -->

  <h3 class="no-margin">Modals with scroll</h3>

  <div class="v-padding">
    <Btn
      text="Open modal!"
      @click="modal1Active = true"
      color="primary"
      class="margin-eighth"
    />
    <Btn
      text="Open small modal!"
      @click="modal2Active = true"
      color="secondary"
      class="margin-eighth"
    />
    <Btn
      text="Open large modal!"
      @click="modal3Active = true"
      color="accent"
      class="margin-eighth"
    />
    <Btn
      text="Open full modal!"
      @click="modal4Active = true"
      color="black"
      class="margin-eighth"
    />
  </div>

  <h3 class="no-margin">Modals without scroll</h3>

  <div class="v-padding">
    <Btn
      text="Open modal!"
      @click="modal5Active = true"
      color="primary"
      class="margin-eighth"
    />
    <Btn
      text="Open small modal!"
      @click="modal6Active = true"
      color="secondary"
      class="margin-eighth"
    />
    <Btn
      text="Open large modal!"
      @click="modal7Active = true"
      color="accent"
      class="margin-eighth"
    />
    <Btn
      text="Open full modal!"
      @click="modal8Active = true"
      color="black"
      class="margin-eighth"
    />
  </div>

  <Modal
    title="The modal title"
    :active="modal1Active"
    @modal-close="modal1Active = false"
  >
    <template v-slot:modal-body>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal1Active = false" />
      <Btn text="Valider" color="primary" />
    </template>
  </Modal>

  <Modal
    title="The small modal title"
    :active="modal2Active"
    @modal-close="modal2Active = false"
    size="sm"
  >
    <template v-slot:modal-body>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal2Active = false" />
      <Btn text="Valider" color="primary" />
    </template>
  </Modal>

  <Modal
    title="The large modal title"
    :active="modal3Active"
    @modal-close="modal3Active = false"
    size="lg"
  >
    <template v-slot:modal-body>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal3Active = false" />
      <Btn text="Valider" color="primary" />
    </template>
  </Modal>

  <Modal
    title="The full modal title"
    :active="modal4Active"
    @modal-close="modal4Active = false"
    size="full"
  >
    <template v-slot:modal-body>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <div style="height: 2000px; width 200px; background: tomato;"></div>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal4Active = false" />
      <Btn text="Valider" color="primary" />
    </template>
  </Modal>

  <Modal
    title="The no-scroll modal title"
    :active="modal5Active"
    @modal-close="modal5Active = false"
    no-scroll
  >
    <template v-slot:modal-body>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal5Active = false" />
      <Btn text="Valider" color="primary" />
    </template>
  </Modal>

  <Modal
    title="The no-scroll, small modal title"
    :active="modal6Active"
    @modal-close="modal6Active = false"
    size="sm"
    no-scroll
  >
    <template v-slot:modal-body>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal6Active = false" />
      <Btn text="Valider" color="primary" />
    </template>
  </Modal>

  <Modal
    title="The no-scroll, large modal title"
    :active="modal7Active"
    @modal-close="modal7Active = false"
    size="lg"
    no-scroll
  >
    <template v-slot:modal-body>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal7Active = false" />
      <Btn text="Valider" color="primary" />
    </template>
  </Modal>

  <Modal
    title="The no-scroll, full modal title"
    :active="modal8Active"
    @modal-close="modal8Active = false"
    size="full"
    no-scroll
  >
    <template v-slot:modal-body>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
      <div style="height: 2000px; width 200px; background: tomato;"></div>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error
        repellendus, alias inventore accusantium reiciendis, voluptas,
        consequuntur assumenda aliquid quos vitae officia delectus
        necessitatibus nihil suscipit. Incidunt suscipit modi doloremque.
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal8Active = false" />
      <Btn text="Valider" color="primary" />
    </template>
  </Modal>

  <hr>

  <!-- SHADOWS -->

  <h2>Shadows</h2>
  <p class="styleguide-p-sourcecode">
    <em>Source code: <code>scss/base/_shadows.scss</code></em>
  </p>

  <div class="grid">
    <div class="tmp-col">
      <div class="styleguide-shadow-block shadow-0">.shadow-0</div>
    </div>
    <div class="tmp-col">
      <div class="styleguide-shadow-block shadow-1">.shadow-1</div>
    </div>
    <div class="tmp-col">
      <div class="styleguide-shadow-block shadow-2">.shadow-2</div>
    </div>
    <div class="tmp-col">
      <div class="styleguide-shadow-block shadow-3">.shadow-3</div>
    </div>
    <div class="tmp-col">
      <div class="styleguide-shadow-block shadow-4">.shadow-4</div>
    </div>
    <div class="tmp-col">
      <div class="styleguide-shadow-block shadow-5">.shadow-5</div>
    </div>
  </div>

  <hr>

  <!-- CAPPED WIDTH -->

  <h2>Capped width</h2>
  <p class="styleguide-p-sourcecode">
    <em>Source code: <code>scss/layout/_capped-width.scss</code></em>
  </p>

  <h3 class="styleguide-h3">.max-width-xs</h3>
  <p class="max-width-xs">
    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi
    neque perspiciatis perferendis, voluptate enim aut, magni optio
    laborum dolorem placeat voluptatibus quis quia. Debitis nihil, quod
    unde incidunt, impedit, assumenda a corporis rem id eligendi
    aspernatur error explicabo excepturi? Quas cumque, natus quaerat
    iste nam sunt sint cum quis perspiciatis adipisci, suscipit error ut
    praesentium dolorem modi exercitationem unde velit laboriosam!
    Velit, veniam sint? Ullam nobis provident reprehenderit, nisi earum
    quae neque minima, aspernatur commodi debitis omnis sed veritatis
    quam ipsum ipsam, quaerat repellat recusandae. Qui consequuntur
    necessitatibus eveniet facilis doloremque.
  </p>

  <h3 class="styleguide-h3">.max-width-sm</h3>
  <p class="max-width-sm">
    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi
    neque perspiciatis perferendis, voluptate enim aut, magni optio
    laborum dolorem placeat voluptatibus quis quia. Debitis nihil, quod
    unde incidunt, impedit, assumenda a corporis rem id eligendi
    aspernatur error explicabo excepturi? Quas cumque, natus quaerat
    iste nam sunt sint cum quis perspiciatis adipisci, suscipit error ut
    praesentium dolorem modi exercitationem unde velit laboriosam!
    Velit, veniam sint? Ullam nobis provident reprehenderit, nisi earum
    quae neque minima, aspernatur commodi debitis omnis sed veritatis
    quam ipsum ipsam, quaerat repellat recusandae. Qui consequuntur
    necessitatibus eveniet facilis doloremque. Dolores officia quaerat
    consequuntur, nemo accusantium accusamus ipsum, ex, explicabo
    aspernatur quam odit quod aperiam magni culpa tenetur? Nihil magni
    magnam nobis dolorum illo quo debitis et accusamus ratione.
  </p>

  <h3 class="styleguide-h3">.max-width-md</h3>
  <p class="max-width-md">
    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi
    neque perspiciatis perferendis, voluptate enim aut, magni optio
    laborum dolorem placeat voluptatibus quis quia. Debitis nihil, quod
    unde incidunt, impedit, assumenda a corporis rem id eligendi
    aspernatur error explicabo excepturi? Quas cumque, natus quaerat
    iste nam sunt sint cum quis perspiciatis adipisci, suscipit error ut
    praesentium dolorem modi exercitationem unde velit laboriosam!
    Velit, veniam sint? Ullam nobis provident reprehenderit, nisi earum
    quae neque minima, aspernatur commodi debitis omnis sed veritatis
    quam ipsum ipsam, quaerat repellat recusandae. Qui consequuntur
    necessitatibus eveniet facilis doloremque. Dolores officia quaerat
    consequuntur, nemo accusantium accusamus ipsum, ex, explicabo
    aspernatur quam odit quod aperiam magni culpa tenetur? Nihil magni
    magnam nobis dolorum illo quo debitis et accusamus ratione.
  </p>

  <hr>

  <!-- FIXED RATIO -->

  <h2>Fixed ratio blocks</h2>
  <p class="styleguide-p-sourcecode">
    <em>Source code: <code>scss/layout/_fixed-ratio.scss</code></em>
  </p>

  <h3 class="styleguide-h3">Square ratio</h3>
  <div class="max-width-xxs">
    <div
      class="fixed-ratio fixed-ratio--1by1"
      style="background-color: tomato"
    ></div>
  </div>

  <h3 class="styleguide-h3">16/9 ratio, responsive media embed</h3>
  <div class="max-width-xs">
    <div
      class="fixed-ratio fixed-ratio--16by9"
      style="background-color: lightseagreen"
    >
      <iframe
        src="https://www.youtube.com/embed/jbi9HEz-cww"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write;
        encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>

</template>

<script>
import { ref } from 'vue'
import Tabs from '@/components/base/Tabs.vue'
import Tab from '@/components/base/Tab.vue'
import Modal from '@/components/layout/Modal.vue'
import TabsMaterial from '@/components/layout/TabsMaterial.vue'
import Timeline from '@/components/layout/Timeline.vue'
import Btn from '@/components/base/Btn.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import ToolMenu from '@/components/layout/ToolMenu.vue'
import NavigationDropdown from '@/components/layout/NavigationDropdown.vue'
import Accordion from '@/components/layout/Accordion.vue'

export default {
  name: 'StyleguideLayoutView',

  components: {
    Tabs,
    Tab,
    Modal,
    TabsMaterial,
    Timeline,
    Btn,
    ToolDropdown,
    MenuItem,
    ToolMenu,
    NavigationDropdown,
    Accordion,
  },

  setup() {
    const active1 = ref(1) // Active tab ID
    const active2 = ref(0) // Active tab ID
    const active3 = ref(2) // Active tab ID
    return { active1, active2, active3 }
  },

  data() {
    return {
      modal1Active: false,
      modal2Active: false,
      modal3Active: false,
      modal4Active: false,
      modal5Active: false,
      modal6Active: false,
      modal7Active: false,
      modal8Active: false,
      modalVFMActive: false,
    }
  },

  mounted() {

  },

  methods: {
    confirm() {
      // some code...
      this.modalVFMActive = false
    },
    cancel(close) {
      // some code...
      close()
    },
    onSubmit() {
      console.log('OnSubmit() called')
    },
  },
}
</script>

<style lang="scss" scoped>
/* STYLEGUIDE - LAYOUT */

// Material shadows

.styleguide-shadow-block {
  padding: $gutter;
  background-color: white;
}
</style>
