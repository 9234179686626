<template>
  <teleport to="#tool-menu">

    <Btn
      class="btn-toggle-tool-panel"
      round
      grow
      icon="kefta-dots"
      iconSize="md"
      @click="emitEventToggleToolMenu"
    />

    <nav class="topbar-menu-nav topbar-menu-nav--tool">
      <ul class="menu">
        <slot />
      </ul>
    </nav>

  </teleport>
</template>

<script>
/**
 * @deprecated
 */

import Btn from '@/components/base/Btn.vue'

export default {
  name: 'ToolMenu',

  components: {
    Btn,
  },

  methods: {
    // EVENT EMITTING
    emitEventToggleToolMenu() {
      this.emitter.emit('toggle-tool-panel')
    },
  },
}
</script>

<style lang="scss" scoped>
// TOOL MENU BUTTON ("kefta")

.btn-toggle-tool-panel {
  padding: ((($touch-target-size-base - $hamburger-size) / 2) - 0.1rem);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;

  :deep(> .icon) {
    transition: all 0.25s;
  }

  // Active button
  .is-panel-tool-open & {
    background-color: $color-primary;
    color: white;

    :deep(> .icon) {
      transform: rotateZ(90deg);
    }

    @include hocus() {
      background-color: $color-primary;
      color: white;
    }

    &::before {
      background-color: rgba(white, 0.25);
    }
  }
}

.topbar-menu-nav--tool {
  /*.app-wrapper*/
  .is-panel-tool-open & {
    transform: translateY(0);
    opacity: 1;
    pointer-events: initial;
  }
}
</style>
